/* Ensure smooth scrolling */
html {
	scroll-behavior: smooth;
}

/* Adjusted to fix spacing */
body {
	margin-bottom: 20px; /* Moves everything slightly up */
}

/* Sidebar container */
.side-nav-container {
	background: linear-gradient(135deg, rgba(38, 98, 148, 0.9), rgba(60, 123, 188, 0.9)); /* Bluish gradient */
	width: 300px;
	height: 100%;
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 999;
	transition: width 0.4s ease, box-shadow 0.3s ease;
	box-shadow: 4px 0 15px rgba(0, 0, 0, 0.2);
	border-radius: 15px 0 0 15px; /* Rounded corners */
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 10px;
}

/* Collapsed sidebar */
.side-nav-container-NX {
	width: 80px;
}

/* Navbar header (logo + toggle button) */
.nav-heading {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px;
}

/* Logo styling */
.logo-image {
	width: 140px; /* Adjusted for better balance */
	height: auto;
	display: block;
	margin: auto;
}

/* Center logo inside container */
.nav-brand {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

/* Hamburger button */
.hamburger {
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.hamburger span {
	display: block;
	background-color: #fff;
	border-radius: 15px;
	height: 5px;
	width: 35px;
	margin: 3px 0;
	transition: 0.4s;
}

/* Sidebar Menu */
.nav-menu {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 10px; /* Adjusted */
}/* Menu Items */
.menu-item {
	display: flex;
	align-items: center;  /* Ensures icon and text are aligned */
	justify-content: flex-start;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	padding: 12px 18px; /* Adjusted padding */
	border-radius: 10px;
	transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
	width: 100%;
}

.menu-item:hover {
	background-color: rgba(0, 0, 0, 0.15);
	transform: translateX(10px);
	box-shadow: 0 4px 8px rgba(0, 123, 255, 0.5);
}

/* Icon Styling */
.menu-item-icon {
	width: 24px;  /* Adjusted size for better alignment */
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 12px;  /* Adjusted spacing between icon & text */
}

/* Text Styling */
.menu-item p {
	font-size: 16px;
	font-weight: bold;
	margin: 0;
	flex: 1;
	text-align: left;
	line-height: 1; /* Ensures text aligns properly */
	display: flex;
	align-items: center; /* Aligns text with the icon */
}

/* Center tooltip when collapsed */
.menu-item-NX {
	display: flex;
	justify-content: center;
}

/* Tooltip */
.menu-item-NX:hover .menu-item-icon + .tooltip {
	visibility: visible;
	opacity: 1;
}

.tooltip {
	position: absolute;
	top: 50%;
	left: 110%;
	transform: translateY(-50%);
	background-color: #333;
	color: #fff;
	text-align: center;
	padding: 8px;
	border-radius: 4px;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s;
}

/* Footer */
.nav-footer {
	width: 100%;
	height: 87px;
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
