#terms {
    /* background-image: url("./homebg.png"); */
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 10px 20px;
    background-color: #080c2c;
}

.dep-terms {

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.border {
    border: 1px solid #ddd;
    background-image: url("./homebg.png");
    border-radius: 4px;
    padding: 1rem;
}

.aboutbox {
    background-color: #377494;
}

.abouthead {
    text-align: center;
}

.hed {
    font-family: Nasalization;
    font-size: 30.22px;
    color: white;
}

.dep-about-content {
    list-style: none;
    padding-left: 0;
    color: white;
}

.infoofdv {
    font-weight: bold;
    color: #E75480;
}