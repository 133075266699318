.accomodation {
    padding: 2vh;
    margin-top: 7vh;

}

.guidelines {
    background-color: #014a82;
    padding: 2vh;
    border-radius: 1vh;


    /* @media(min-width:768px) {
        margin-left: 85px;
    } */

}



.guidelines li {
    margin: 10px;

}

.accomodation-btn {
    display: flex;
    justify-content: center;


}

.get-accomodation-btn {
    background-color: #014a82;
    color: white;
    padding: 20px 30px;
    align-items: center;

    justify-content: center;
    margin-top: 20px;

    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.get-accomodation-btn:hover {
    background-color: #012d51;

}