@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");



.contact {
    width: 100%;
    background-size: cover;
    background-position: top;
    padding: 3%;
    font-family: "'Montserrat', sans-serif";
}

.contact h1 {
    text-align: center;
    color: white;
    font-size: 50px;
    margin: 1%;
    letter-spacing: 0.05rem;
}

.contact h3 {
    text-align: center;
    color: rgb(211, 211, 211);
    font-size: 30px;
    margin: 2%;
    margin-bottom: 2%;
    letter-spacing: 0.05rem;
    margin-top: revert-layer;
}



.contact h4 {
    text-align: center;
    color: white;
    font-size: 20px;
    margin: 2%;
    margin-bottom: 4%;
    letter-spacing: 0.15rem;
}

/* .contact .cards {
    border-radius: 20px;
    padding: 2% 0;
    overflow: hidden;
    margin-top: -40px;

} */
.sponsors-card-image img {
    border-radius: 50%;
    width: 140px;
    height: 140px;

}

.contact .inner-card {
    display: flex;

    @media(max-width:768px) {
        flex-direction: column;
    }

}

.contact .card {
    /* background-image: linear-gradient(#040f1a, #040f1a, #040f1a); */
    height: 320px;
    margin: 1vw;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    width: 20%;
}

.expo {
    border: 3px solid white;
    border-radius: 50%;
    display: inline-block;
    margin: 0 auto;
    height: 20rem;
    width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expo:hover {
    /* transform: scale(1.15); */
    border: 3px solid black;
    box-shadow: 0 4px 8px 0 #3498db;
}

.expo:hover h3 {
    transform: scale(1.15);
    transition: 0.2s all ease;
}

.expo span {
    color: #3498db;
}

.circle {
    width: 20rem;
    height: 20rem;
    background-color: #3498db;
    /* Choose your desired background color */
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

.circle p {
    color: white;
    /* Choose your desired text color */
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
}

/* .card .image {

    width: 200px;
    height: 200px;
    overflow: hidden;
    text-align: center;
    margin: 20px auto;
    border-radius: 50%;
    margin-bottom: 0;
}

.card .image img {
    object-fit: cover;
    object-position: top;
} */



@media screen and (max-width: 768px) {
    .contact .card {
        width: 90%;
        margin-top: 10px;
    }

    .contact h1 {
        margin-top: 40px;
    }

    .contact h3 {
        font-size: 30px;
    }

    .contact .cards {
        flex-direction: column;

    }

    .contact .inner-card {
        flex-wrap: nowrap;

    }


}