.workshops-container {
    padding: 2vh;
    margin-top: 7vh;
  }
  
  .guidelines-workshops {
    background-color: #014a82;
    padding: 2vh;
    border-radius: 1vh;
  }
  
  .workshop-heading {
    font-weight: 550;
    margin-left: 1.5vh;
    margin-bottom: 1.5vh;
  }
  
  .workshops-list li {
    margin: 1vh 0;
  }
  
  .workshops-cards {
    margin-top: 2vh;
    display: flex;
    gap: 2vh;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .workshop-card {
    background-color: #403c3c;
    width: 45%;
    padding: 2vh;
    border-radius: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .workshop-title {
    font-weight: 550;
    margin-bottom: 1.5vh;
  }
  
  .workshop-instructor {
    font-weight: 500;
    margin-bottom: 1vh;
  }
  
  .workshop-details {
    padding: 2vh;
    border-radius: 1vh;
    text-align: left;
  }
  
  .workshop-buttons {
    display: flex;
    gap: 1.5vh;
    margin-top: 1.5vh;
  }
  
  .workshop-btn {
    background-color: white;
    color: black;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .workshop-btn:hover {
    background-color: #e0e0e0;
  }
  
  @media (max-width: 768px) {
    .workshops-cards {
      flex-direction: column;
    }
  
    .workshop-card {
      width: 100%;
    }
  }
  