#map {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.map-img {
    width: 600px;
    height: 600px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    object-fit: cover;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .map-img {
        width: 400px;
        height: 400px;
    }
}

.slabs {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.slab1,
.slab2 {
    margin: 0;
}

.why {
    display: grid;
    gap: 1rem;
    margin: 0 auto;
    padding: 2rem;
}

@media (min-width: 640px) {
    .why {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 768px) {
    .why {
        grid-template-columns: repeat(3, 1fr);
    }
}

.hed {
    font-family: "Nasalization", sans-serif;
    font-size: 36px;
    color: white;
    text-align: center;
}

.whybox {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 1rem;
    background-color: #377494;
    margin-bottom: 1rem;
}

.whyboxcontent ul {
    list-style: none;
    padding-left: 0;
}

.whyboxcontent li {
    color: white;
    margin-bottom: 0.5rem;
}
