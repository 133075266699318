@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

body {
    width: 100vw;
}

.about {
    width: 100%;
    background-size: cover;
    background-position: top;
    padding: 1%;

    @media(min-width: 768px) {
        margin-left: 85px;
    }
}

.about h1 {
    text-align: center;
    color: white;
    font-size: 30px;
    margin: 2%;
    margin-bottom: 2%;
    letter-spacing: 0.05rem;
}

.about h3 {
    text-align: center;
    color: white;
    font-size: 20px;
    margin: 2%;
    margin-bottom: 4%;
    letter-spacing: 0.05rem;
}

.about p {
    color: white;
    margin: 1%;
    font-size: 14px;
    letter-spacing: 0.05rem;
}