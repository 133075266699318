.paper-presentation {
    padding: 3vh;
    margin-top: 8vh;
}

/* Paper Presentation Guidelines Box */
.guidelines-ppc {
    background: linear-gradient(135deg, #014a82, #026ba3); /* Matching competition theme */
    padding: 2vh;
    border-radius: 1vh;
    color: white;
    font-weight: 500;
    box-shadow: 0px 4px 10px rgba(1, 74, 130, 0.4);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.guidelines-ppc:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 15px rgba(1, 74, 130, 0.6);
}

/* Upper Text and Button Layout */
.upper-text-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .upper-text-btn {
        flex-direction: column;
        text-align: center;
    }
}

/* Text Content */
.ppc-text {
    width: 70vw;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .ppc-text {
        width: 100%;
        text-align: center;
    }
}

/* Register Button Section */
.ppc-register {
    text-align: center;
}

.ppc-register h3 {
    font-weight: bold;
}

/* Register Button */
.register-btn-ppc {
    background: white;
    color: black;
    padding: 15px 40px;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    margin-top: 10px;
}

.register-btn-ppc:hover {
    background: #e0e0e0;
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.3);
}

/* Bullet Points */
.guidelines-ppc ul {
    list-style-type: none;
    padding: 0;
}

.guidelines-ppc li {
    margin: 1vh 0;
    font-size: 1rem;
}

/* Contact Info */
.guidelines-ppc p {
    font-style: italic;
}
