/* Modernized Competitions Page with Enhanced UI */

.Competitions {
    padding: 4vh 6vw;
    margin-top: 5vh;
    background: linear-gradient(135deg, #0F2027, #0581aa, #0887bd);
    min-height: 100vh;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
    color: white;
    font-family: 'Poppins', sans-serif;
}

/* Enhanced Guidelines Section */
.guidelines-competitions {
    background: rgba(10, 13, 95, 0.137);
    backdrop-filter: blur(12px);
    padding: 3vh;
    border-radius: 18px;
    font-weight: 600;
    transition: all 0.4s ease-in-out;
    box-shadow: 0 5px 12px rgba(255, 255, 255, 0.2);
}

.guidelines-competitions:hover {
    transform: translateY(-6px);
    box-shadow: 0px 8px 20px rgba(255, 255, 255, 0.4);
}

.guidelines-competitions ul {
    list-style-type: none;
    padding: 0;
}

.guidelines-competitions li {
    margin: 1.5vh 0;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

.guidelines-competitions li::before {
    content: "\2714";
    color: #FFD700;
    font-weight: bold;
    margin-right: 12px;
}

/* Grid Layout for Competitions */
.competition-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 3vh;
    justify-content: center;
    margin-top: 4vh;
}

.competition-cards .card {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(15px);
    border-radius: 15px;
    padding: 24px;
    transition: all 0.4s ease-in-out;
    box-shadow: 0px 6px 18px rgba(255, 255, 255, 0.25);
}

.competition-cards .card:hover {
    transform: translateY(-8px) scale(1.05);
    box-shadow: 0px 10px 25px rgba(255, 255, 255, 0.5);
}

/* Stylized Accommodation Button */
.accomodation-btn {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
}

.get-accomodation-btn {
    background: linear-gradient(90deg, #00628c, #0082c8);
    color: white;
    padding: 16px 32px;
    font-size: 1.3rem;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.get-accomodation-btn:hover {
    background: linear-gradient(90deg, #00628c, #0082c8);
    transform: scale(1.1);
    box-shadow: 0px 8px 20px rgba(255, 255, 255, 0.3);
}

/* Mobile Friendly Adjustments */
@media (max-width: 768px) {
    .competition-cards {
        grid-template-columns: 1fr;
        gap: 4vh;
    }

    .get-accomodation-btn {
        width: 100%;
        text-align: center;
    }
}
