/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 100;
  overflow-x: hidden;
  position: relative;
  background-color: #000000 !important;
  color: #fff !important;
  font-family: 'Poppins', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 50%;
  height: auto;
  margin-left: 0px;
  margin-top: 6%;
  margin-bottom: 5px;
}

button {
  /* border: 0; */
  /* background-color: transparent; */
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}



/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  /* background-image: url('./assets/img/banner-bg.png'); */
  background-color: rgb(27, 27, 27);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  /* height: 100vh; */

}

.banner .tagline1 {
  /* color: #0C93DE; */
  font-weight: 400;
  letter-spacing: 0.8px;
  /* padding: 8px 5px; */
  /* background: rgba(39, 68, 85, 255); */
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
}


.banner .tagline {
  font-weight: 400;
  letter-spacing: 0.8px;
  padding: 10px 16px;
  background: #014a82;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  font-size: 16px;
  margin-bottom: 20px;
  display: inline-block;
  border-radius: 4px;
}

.banner .tagline-date {
  font-weight: 400;
  letter-spacing: 0.8px;
  font-size: 16px;
  margin-bottom: 20px;
  display: inline-block;
}

.banner h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}

.banner p {
  color: #cbcbcb;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 120%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  /* animation: updown 3s linear infinite; */
  margin-left: 220px;
}

/* .banner p {
  word-wrap: break-word;
} */

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

.links-container {
  position: fixed;
  top: 48px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1000;
}

.register-tag,
.alum-support-tag {
  margin-left: 20px;

  display: inline-block;

  color: #fff;

  border-radius: 2px;
  text-decoration: none;

}

.register-tag span,
.alum-support-tag span {
  color: #fff;

  margin-left: 5px;

}




@media (max-width: 767px) {
  .links-container {
    top: 55px;
    margin-right: 0px;
  }

  .alum-support-tag {
    margin-top: -15px;
  }

  @media (max-width: 767px) {
    .banner p {
      width: 90%;

    }
  }

  .register-tag,
  .alum-support-tag {
    margin-left: 0;

    margin-bottom: 10px;

  }

  .banner {
    padding: 150px 0 100px 0;
    /* Adjust padding for smaller screens */
  }

  .banner img {
    margin-left: 100px;
    /* Adjust for smaller screens */
  }


}

/* 
.shining-effect {
  position: relative;
  overflow: hidden;
}

.shining-effect:before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(0deg);
  opacity: 0;
  animation: shine 1.5s infinite linear;
}

@keyframes shine {
  0% {
    opacity: 0;
    transform: rotate(0deg) translateX(-50%) translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: rotate(360deg) translateX(-50%) translateY(-50%);
  }
} */
.shining-effect {
  position: relative;
  overflow: hidden;
}

.shining-effect:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  opacity: 0;
  animation: shine 1.5s infinite linear;
}

@keyframes shine {
  0% {
    opacity: 0;
    left: -100%;
  }

  100% {
    opacity: 1;
    left: 100%;
  }
}


.links-container .register-tag:hover,
.links-container .register-tag:active {
  animation-play-state: paused;
  opacity: 1;

}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;

  }
}

.register-btn-unstop {
  background-color: white;
  color: black;
  padding: 20px 50px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register-btn-unstop:hover {
  background-color: #e0e0e0;

}


/************ Projects Css ************/
.project {
  padding: 30px 0;
  position: relative;
  background-color: black;
}

.project h2 {
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}

.project p {
  color: #DDDDDD;
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 80%;
}

.project .nav.nav-pills {
  width: 100%;
  margin: 0 auto;
  border-radius: 0px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(56, 56, 147, 1) 0%,
      rgba(0, 212, 255, 1) 100%);
  ;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* border-radius: 55px 0px 0px 55px; */
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* border-radius: 0 55px 55px 0; */
}

.proj-imgbx {
  position: relative;
  border-radius: 1vh;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx::before {
  content: "";
  background: #012d51;
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 20vw;
  max-height: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
  width: 15vw;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.small-font {
  font-size: 8px;
}

/* ... (Previous styles) ... */

/* Initial styles for mobile */
@media (max-width: 767px) {
  .proj-imgbx::before {
    height: 0;
  }

  .proj-txtx {
    top: 50%;
    opacity: 0;
  }

  .proj-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
  }

  .short_brief,
  p {
    font-size: 14px;
  }

  .small-font {
    font-size: 6px;
  }
}

/* Hover styles for mobile */
@media (max-width: 767px) {
  .proj-imgbx:hover::before {
    height: 100%;
  }

  .proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
  }

  .ps-title {
    font-size: 10px;
  }
}