@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");



.contact {
  width: 100%;

  background-size: cover;
  background-position: top;
  padding: 3%;
  font-family: "'Montserrat', sans-serif";
}

.contact h1 {
  text-align: center;
  color: white;
  font-size: 30px;
  margin: 1%;
  /* margin-bottom: 4%; */
  letter-spacing: 0.05rem;
}

.contact h3 {
  text-align: center;
  color: rgb(211, 211, 211);
  font-size: 20px;
  margin: 2%;
  margin-bottom: 2%;
  letter-spacing: 0.05rem;
  margin-top: revert-layer;
}

.contact span {
  color: rgb(211, 211, 211);
}

.contact h4 {
  text-align: center;
  color: white;
  font-size: 20px;
  margin: 2%;
  margin-bottom: 4%;
  letter-spacing: 0.15rem;
}

.contact .cards {
  /* background: rgba(59, 116, 148, 0.3);  */
  /* border: 3px solid rgba(133, 133, 133, 0.5); */
  border-radius: 20px;
  /* display: flex; */
  /* justify-content: space-evenly; */
  /* flex-wrap: wrap; */
  padding: 2% 0;
  overflow: hidden;
  margin-top: -40px;
  /* min-height: 220vh; */
}

.contact .inner-card {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-height: 40vh;

  align-items: center;

}

.contact .card {
  background-image: linear-gradient(#040f1a, #040f1a, #040f1a);
  height: 320px;
  margin: 1vw;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  width: 20%;
}

.card .image {
  /* border: 2px solid red; */
  width: 200px;
  height: 200px;
  overflow: hidden;
  text-align: center;
  margin: 20px auto;
  border-radius: 50%;
  margin-bottom: 0;
}

.card .image img {
  object-fit: cover;
  object-position: top;
}

.card .text {
  margin-top: 10px;
  /* border: 2px solid yellow; */
  text-align: center;
  color: rgb(211, 211, 211);
}

.card .text .name {
  font-size: 18px;
  margin: 6px 0;
}

.card .text .no {
  font-size: 12px;
  margin: -1px;
}

i {
  font-size: 12px;
}

.card .text .email {
  font-size: 18px;
  margin-bottom: 3px;
}

.card .fa {
  font-size: 20px;
  margin-right: 7px;
}

.card .links {
  /* border: 2px solid green; */
  height: 80px;
}

.card .links {
  color: rgb(211, 211, 211);
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */
  /* width: 100%; */
}

.links a {
  padding: 0 5px !important;
}

.card .links .fa {
  padding: 0;
  margin: 0 3%;
  font-size: 1.9rem;
}

.card .links a {
  color: rgb(211, 211, 211);
}

.fa-facebook:hover {
  color: #3b5998;
}

.fa-linkedin:hover {
  color: #0077b5;
}

.fa-facebook:hover {
  /* border: 2px solid black; */
  /* border-radius: 50%; */
  /* padding: 10px; */
  /* box-shadow: ; */
}

.contact .card:hover {
  transform: scale(1.03);
  /* border: 2px solid black; */
  /* box-shadow: 0 4px 8px 0 rgb(24, 24, 24); */

}

@media screen and (max-width: 768px) {
  .contact .card {
    width: 90%;
    margin-top: 10px;
  }

  .contact h3 {
    font-size: 30px;
  }
}